$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1580px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1600px
);

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

$app-brand-orange: #FF6C0E !default;
$app-brand-color: #06c27d !default;
$app-skeleton-margin: 85px !default;

/**
 * Mixin to add common CSS properties for allowing an element to show the ellipsis, in case
 * the entire text is not being rendered due to space constraints.
 */
@mixin text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
