@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import '@angular/material/theming';
@import '@material/shape';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
$my-blue: (
  50: #7fdddd,
  100: #7faedd,
  200: #7f7fdd,
  300: #7faedd,
  400: #7faedd,
  500: #7faedd,
  600: #7faedd,
  700: #7faedd,
  800: #7faedd,
  900: #7faedd,
  A100: #7faedd,
  A200: #7faedd,
  A400: #7faedd,
  A700: #7faedd,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);

$havrion-orange: (
    50: #FFEDE2,
    100: #FFD3B7,
    200: #FFB687,
    300: #FF9856,
    400: #FF8232,
    500: #FF6C0E,
    600: #FF640C,
    700: #FF590A,
    800: #FF4F08,
    900: #FF3D04,
    A100: #FFFFFF,
    A200: #FFF4F2,
    A400: #FFCBBF,
    A700: #FFB5A6,
    contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
    )
);

$htv-green: (
  50: #e1f8ef,
  100: #b4edd8,
  200: #83e1be,
  300: #51d4a4,
  400: #2bcb91,
  500: #06c27d,
  600: #05bc75,
  700: #04b46a,
  800: #03ac60,
  900: #029f4d,
  A100: #c9ffe0,
  A200: #96ffc2,
  A400: #63ffa4,
  A700: #4aff95,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
    )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$havrion-primary: mat-palette($htv-green);
$havrion-accent:  mat-palette($havrion-orange);

$small-component-radius: --mdc-shape-small;
$medium-component-radius: --mdc-shape-small;
$large-component-radius: --mdc-shape-small;


// The warn palette is optional (defaults to red).
$havrion-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$havrion-theme: mat-light-theme($havrion-primary, $havrion-accent, $havrion-warn);


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($havrion-theme);

:root {
  --iptv-brand-green: #06C27D;
  --iptv-brand-green-rgb: 6, 194, 125;
  --iptv-warn-rgb: 226, 0, 0;

  --iptv-brand-orange: #FF6C0E;

  --iptv-warn: #E20000;
}

$havrion-brand-color: #FF6C0E;
$htv-brand-color: #06C27D;
// $havrion-brand-color: #ff6c0e;
$havrion-medium-white: #D9D8D6; 
$havrion-light-grey: #474848;
$havrion-medium-grey: #222;
$havrion-dark-grey: #1E1F1F;
$havrion-fcolor-white: #fff;
$havrion-fcolor-dark: #555;

/** page elements **/
$toolbar-bg-color: #2f2f2f;
$font-color: $havrion-fcolor-white;

$card-detail-bg-color: $havrion-light-grey;
$card-detail-header-bg-color: $havrion-medium-grey;
$card-detail-title-bg-color: $havrion-dark-grey;
$card-detail-border-radius: 5px;

$dash-card-bg-color: $havrion-medium-grey;

$btn-bg-color: $havrion-brand-color;
$btn-fcolor: $havrion-brand-color;
$btn-border-color: $havrion-brand-color;
$btn-border: 2px solid $btn-border-color;
$btn-border-px: 3px;
$btn-border-radius: 30px;
