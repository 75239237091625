@font-face {
  font-family: "Gilroy";
  src: url("../../fonts/Gilroy-Light.eot");
  src: url("../../fonts/Gilroy-Light.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Gilroy-Light.svg#Gilroy-Light") format("svg"),
    url("../../fonts/Gilroy-Light.ttf") format("truetype"),
    url("../../fonts/Gilroy-Light.woff") format("woff"),
    url("../../fonts/Gilroy-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../fonts/Gilroy-Medium.eot");
  src: url("../../fonts/Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Gilroy-Medium.svg#Gilroy-Medium") format("svg"),
    url("../../fonts/Gilroy-Medium.ttf") format("truetype"),
    url("../../fonts/Gilroy-Medium.woff") format("woff"),
    url("../../fonts/Gilroy-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../fonts/Gilroy-Regular.eot");
  src: url("../../fonts/Gilroy-Regular.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Gilroy-Regular.svg#Gilroy-Regular") format("svg"),
    url("../../fonts/Gilroy-Regular.ttf") format("truetype"),
    url("../../fonts/Gilroy-Regular.woff") format("woff"),
    url("../../fonts/Gilroy-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../fonts/Gilroy-Bold.eot");
  src: url("../../fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Gilroy-Bold.svg#Gilroy-Bold") format("svg"),
    url("../../fonts/Gilroy-Bold.ttf") format("truetype"),
    url("../../fonts/Gilroy-Bold.woff") format("woff"),
    url("../../fonts/Gilroy-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../fonts/Gilroy-Heavy.eot");
  src: url("../../fonts/Gilroy-Heavy.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Gilroy-Heavy.svg#Gilroy-Heavy") format("svg"),
    url("../../fonts/Gilroy-Heavy.ttf") format("truetype"),
    url("../../fonts/Gilroy-Heavy.woff") format("woff"),
    url("../../fonts/Gilroy-Heavy.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}
