body,
mat-card {
  font-family: "Gilroy-Light", "Helvetica Neue", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gilroy-Regular", "Helvetica Neue", sans-serif;
  letter-spacing: 1px;
  color: #555;
}

h1 {
  text-transform: inherit;
  color: #d9d8d6;
  font-size: 2rem;
}

button {
  font-family: "Gilroy-Medium", "Helvetica Neue", sans-serif !important;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.mat-form-field-label,
.mat-form-field {
  font-family: "Gilroy-Light", "Helvetica Neue", sans-serif !important; /*will fix this*/
}
