/* You can add global styles to this file, and also import other style files */
// @import "../../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css"; // Can be changed for custom material theme
@import "./partials/havrion-font";
@import "./fonts/fonts";
@import "./partials/havrion-typography";
@import "./partials/havrion-theme";
@import "./partials/include-media";
@import "variables";
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

/**
 * Add the necessary margin so the content avoid the header (with HAVRION logo) and
 * the left-side navigation bar.
 *
 * Based on `.content-playlist-container`. See:
 *
 * - src/app/features/secured-skeleton/content-playlist/content-playlist.component.scss
 * - src/app/features/secured-skeleton/devices/devices.component.scss
 * - src/app/features/secured-skeleton/devices/devices-authorize/devices-authorize.component.scss
 * - src/app/features/secured-skeleton/devices/devices-main/devices-main.component.scss
 */
.page {
  margin: 80px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  color: white;
}

html,
body {
  height: 100%;
  box-sizing: border-box;
}

body {
  background: transparent
    radial-gradient(closest-side at 51% 40%, #232323 0%, #0d0d0d 100%) 0% 0%
    repeat padding-box;

  font-family: "Gilroy-Light", "Helvetica Neue", sans-serif;
  color: #fff;
}

*::-webkit-scrollbar {
  width: 0.7rem;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
  background-color: #222;
}

*::-webkit-scrollbar-thumb {
  background-color: #06c27d;
  border-radius: 80px;
  outline: 1px solid #02774c;
}

button {
  background-color: #06c27d;
  border-radius: 30px !important;
  color: #fff;
}

.drag-box {
  position: fixed;
  z-index: 999;
  transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.20), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  &-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    &-handler{
      &-top,&-bottom,&-left,&-rigth{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        cursor: move;
        user-select: none;
        img{
          -moz-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          user-select: none;
          -moz-user-drag: none;
          -khtml-user-drag: none;
          -webkit-user-drag: none;
          pointer-events: none;
          user-select: none;
        }
      }
      &-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 8px;
      }
    }
  }
  &.free-dragging {
    box-shadow: 0px 11px 15px -7px rgba(255, 255, 255, 0.12), 0px 24px 38px 3px rgba(255, 255, 255, 0.08), 0px 9px 46px 8px rgba(255, 255, 255, 0.02);
  }
}



.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  border-radius: 50px !important;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.32);
}

.mat-stroked-button {
  border: 2px solid;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.mat-menu-panel {
  border-radius: 10px !important;
  max-width: 400px !important;
  font-family: "Gilroy-Regular", Arial, Helvetica, sans-serif;
  background-color: #232323;

  .mat-menu-item {
    font-family: "Gilroy-Regular", Arial, Helvetica, sans-serif;
    color: #fff;

    .mat-icon {
      margin-left: 16px;
      font-size: 20px;
      height: 20px;
      width: 20px;
      color: #fff;
      // color: var(--iptv-brand-green);
    }
  }

  a:hover {
    text-decoration: none;
  }

  a.menu-selected {
    color: var(--iptv-brand-green);
    font-family: "Gilroy-Bold", Arial, Helvetica, sans-serif;
    // font-weight: bold;

    .mat-icon {
      color: var(--iptv-brand-green);
    }
  }

  .iptv-menu-warn {
    color: var(--iptv-warn);

    .mat-icon {
      color: var(--iptv-warn);
    }
  }
}

a {
  color: #06c27d;
  text-decoration: none;
  font-weight: bold;
  font-family: "Gilroy-Regular", Arial, Helvetica, sans-serif;

  &:hover {
    text-decoration: underline;
    color: #089d66;
  }
}

.nav-module {
  &__settings-header {
    font-weight: 600;
  }

  &__settings-sub-sections {
    a {
      font-weight: 400;
    }
  }
}

.btn-primary--iptv {
  background-color: #06c27d;
}

.episode-error-dialog {
  .mat-dialog-container {
    min-width: 500px !important;
  }
}

.mat-datetimepicker-content {
  background-color: #222 !important;

  .mat-datetimepicker-calendar-table-header {
    color: #fff !important;
  }

  .mat-datetimepicker-calendar-body-selected {
    color: #fff !important;
    background-color: #ff6c0e !important;
  }

  .mat-datetimepicker-calendar-body-cell-content {
    color: rgba(255, 255, 255, 0.4);

    &:hover {
      background-color: #ff6c0e66 !important;
    }
  }

  .mat-datetimepicker-calendar-body-disabled
    > .mat-datetimepicker-calendar-body-cell-content:not(
      .mat-datetimepicker-calendar-body-selected
    ) {
    color: rgba(255, 255, 255, 0.2);
  }

  .mat-datetimepicker-clock {
    background-color: #e0e0e022 !important;
  }

  .mat-datetimepicker-clock-cell {
    color: rgba(255, 255, 255, 0.4) !important;

    &:hover {
      background-color: #ff6c0e66 !important;
    }
  }

  .mat-datetimepicker-clock-cell-selected {
    color: #fff !important;
    background-color: #ff6c0e !important;
  }

  .mat-datetimepicker-calendar-body-label {
    color: #fff !important;
  }

  .mat-datetimepicker-calendar-header-date-time {
    font-size: 25px !important;
  }
}

/*** mat dialog styles **/
.mat-dialog-container {
  background-color: #2e2e2e;
  min-width: 900px;
  border-radius: 20px !important;
  color: #fff;

  .iptv-dialog__header {
    background-color: #222;
    border-radius: 20px 20px 0 0;
    width: calc(100% + 48px);
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    padding-bottom: 10px;
    margin-top: -24px;
    margin-left: -24px;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.4);

    svg {
      height: 25px;
      // margin-bottom: 11px;
      margin-left: -7px;
      margin-right: 10px;
    }

    h3 {
      display: inline-block;
      margin: 0;
      font-size: 1.3rem;
      line-height: 1.3rem;
    }

    // &--tabs::after {

    // content: "";
    // position: absolute;
    // background-color: #252525;
    // opacity: .3;
    // width: inherit;
    // height: 49px;
    // margin-top: 10px;
    // left: 0;
    // z-index: 10;

    // }
  }

  .dialog-content-tabs {
    border-top: 50px solid #252525;
    width: inherit;
  }

  h1 {
    // color: var(--iptv-brand-green);
    color: #fff;
    font-size: 2rem;
  }

  .dialog-buttons {
    background-color: #222;
    border-radius: 0 0 20px 20px;
    width: 100%;
    text-align: right;
    padding: 24px;
    margin-bottom: -24px;
    margin-left: -24px;

    button {
      min-width: 130px;
    }
  }

  mat-dialog-actions button {
    min-width: 130px;
    text-transform: uppercase;
  }

  .mat-dialog-content {
    color: #fff !important;
    min-height: 350px;

    &.message {
      min-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

/***** dialog tabs ***/
.mat-tab-label,
.mat-tab-link {
  color: rgba(255, 255, 255, 0.82);
}

.episode-details .tabgroup-container {
  .mat-tab-label.mat-tab-label-active {
    background-color: transparent;
  }

  .mat-tab-label {
    color: rgba(255, 255, 255, 0.82);
    max-width: 150px;
  }

  .mat-tab-header {
    position: relative;
    margin-top: -48px;
    z-index: 10;
    border-bottom: 2px solid rgba(255, 255, 255, 0.05);
  }

  .mat-tab-link.mat-tab-label-active {
    background-color: rgba(180, 237, 216, 0);
    border-radius: 0 0 0 0;
    // color:rgba(255, 255, 255, 1) !important;
  }

  .mat-form-field-type-mat-select {
    width: 100%;

    .mat-select-arrow {
      opacity: 0;
    }

    .mat-form-field-suffix {
      color: #06c27d;
    }

    .mat-form-field-flex {
      border-radius: 100px 100px 100px 100px !important;
      border: 2px solid #06c27d;
      padding: 0.5em 0.5em 0 0.9em;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-infix {
      width: 90px;
    }
  }

  .mat-slide-toggle {
    height: auto;

    .mat-slide-toggle-label {
      flex-direction: column;
      align-items: flex-start;

      .mat-slide-toggle-content {
        font-size: 12px !important;
        color: #06c27d;
        line-height: 30px;
        order: 1;
      }

      .mat-slide-toggle-bar {
        margin-left: 5px;
        order: 2;
      }
    }
  }

  .mat-form-field-underline::before {
    background-color: #06c27d !important;
  }
}

.device-card {

  &-grid{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
  }

  padding: 0px !important;
  .header-sellect-all{
    .mat-checkbox-frame{
      border-color: rgba(255, 255, 255, 0.5);
    }
    .mat-checkbox-background{
      background-color: #272727AA;
    }
  }
  .mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: #06c27d;
  }
  .mat-card-header-text{
      display: none;
  }
  .mat-card-content{
    margin-bottom: 0px;
  }
  .mat-card-actions{
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 0px !important;
    padding: 0px;
    border-radius: 0px 0px 15px 15px;
  }
}

.device-edit-dialog {
  .mat-form-field .mat-form-field-ripple {
    opacity: 1;
    background-color: rgba(var(--iptv-brand-green-rgb), 1);
    transform: none;
  }
  .mat-form-field.mat-form-field-invalid .mat-form-field-ripple, .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
    background-color: #f44336;
  }
  .mat-form-field-label {
    color:var(--iptv-brand-green);
  }
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: rgba(255, 255, 255, 0.3);
  }
  .readonly {
    .mat-form-field-label {
      color: rgba(255, 255, 255, 0.5) !important;
    }
    .mat-input-element{
      color: rgba(255, 255, 255, 0.5) !important;
    }
  }
  .readonly.mat-form-field-appearance-fill .mat-form-field-flex{
    background-color: rgba(255, 255, 255, 0.05)
  }
  .readonly.mat-focused .mat-form-field-label{
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .device-edit-chip-list .mat-form-field-label {
    color: var(--iptv-brand-green) !important;
  }

  .mat-standard-chip{
    min-height: 24px;
  }

  .mat-form-field-should-float{
    .mat-form-field-appearance-legacy .mat-form-field-infix {
      padding: 0.4375em 0 0 0;
    }
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: .5em .75em 0 .75em;
  }

  .mat-form-field.iptv-select{
    .mat-form-field-flex {
      padding: 0.2em 1.2em 0 1.2em;
    }
    .mat-form-field-infix {
      width: 100px;
      padding: 0.25em 0 0.2em 0;
    }
    &.mat-form-field-should-float{
      .mat-form-field-infix {
        width: 100px;
        padding: 0.25em 0 0.1em 0;
      }
      .mat-form-field-flex {
          padding: 0.4em 1.2em 0 1.2em;
      }
    }
    .mat-form-field-flex {
      border: 1px solid rgba(var(--iptv-brand-green-rgb), 1);
    }
    &.mat-focused .mat-form-field-flex {
      border: 1px solid rgba(var(--iptv-brand-green-rgb), 1);
    }
    &.mat-form-field-invalid .mat-form-field-flex{
      border: 1px solid rgba(var(--iptv-warn-rgb), 1);
    }
  }

}

.devices-header-sellect-all{
  .mat-checkbox-frame{
    border-color: rgba(255, 255, 255, 0.3);
  }
}


.devices-tools-bar {
  .mat-form-field-type-mat-select {
    width: 100%;
    .mat-select-arrow {
      opacity: 0;
    }
    .mat-form-field-suffix {
      color: #06c27d;
    }
    .mat-form-field-flex {
      border-radius: 100px 100px 100px 100px !important;
      border: 2px solid #06c27d;
      padding: 0.5em 0.5em 0 0.9em;
    }
    .mat-form-field-underline {
      display: none;
    }
    .mat-form-field-infix {
      width: 90px;
    }
    .mat-select-placeholder {
      color: rgba(255, 255, 255, 0.3);
    }
  }

  .mat-form-field{
    min-width: 100px;
    .mat-form-field-wrapper{
      padding-bottom: 0px;
    }
  }
}

.table-container {
  .mat-checkbox-frame {
    border: 2px solid #06c27d88;
  }
}

.tools-select-panel {
  background-color: #161616;
  border: 1px #3c3c3c solid;
  margin-top: 35px;
  margin-left: 15px;
  .mat-option {
    border: none;
  }
}

.action-tools-select-panel{
  background-color: #161616;
  border: 1px #3c3c3c solid;
  margin-top: 35px;
  margin-left: 103px;
  .mat-option{
    border: none;
  }
}

.iptv-dialog .mat-tab-header {
  position: fixed;
  margin-top: -48px;
  z-index: 10;
}

.mat-tab-group,
.mat-radio-button,
.mat-select {
  font-family: "Gilroy-Regular", Arial, Helvetica, sans-serif;
}

.mat-tab-label-active,
.mat-tab-group.mat-primary
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-label.mat-tab-label-active,
.mat-tab-link.mat-tab-label-active {
  background-color: rgba(180, 237, 216, 0.03);
  border-radius: 0 0 0 0;
  // color:rgba(255, 255, 255, 1) !important;
}

.mat-ink-bar {
  height: 4px !important;
  border-radius: 50px !important;
}

.mat-tab-label.mat-tab-label-active,
.mat-tab-link.mat-tab-label-active {
  color: var(--iptv-brand-green);
  opacity: 1;
}

/*** form fields **/
.mat-form-field {
  min-width: 350px;
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.42);
}

.mat-form-field-appearance-fill .mat-form-field-flex:hover {
  .mat-form-field-appearance-fill .mat-form-field-underline::before {
    background-color: rgba(255, 255, 255, 0.42);
  }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(34, 34, 34, 0.82);
  border-radius: 10px 10px 0 0 !important;
  padding: 0.5em 0.75em 0 0.75em;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.5em 0;
}

.mat-form-field.ng-tns-c93-5.mat-primary.ng-star-inserted.mat-form-field-type-mat-select {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 50px !important;
    padding: 0.75em 1.75em 0 1.75em;
  }
}

.mat-input-element:disabled {
  color: white;
}

.mat-radio-button {
  margin-left: 30px;
}

// .mat-form-field-disabled {
// background-color: rgba(0, 0, 0, 0.12);
// }

.mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}

.mat-hint {
  color: #929292;
}

.mat-select-value {
  color: var(--iptv-brand-green);
}

.mat-input-element::placeholder,
.mat-focused .placeholder {
  color: rgba(255, 255, 255, 0.6);
}

// select
.mat-form-field.iptv-select {
  min-width: 50px;

  .mat-form-field-infix {
    width: 100px;
    padding: 0.25em 0 0.5em 0;
  }

  .mat-select-arrow {
    color: var(--iptv-brand-green);
  }

  .mat-form-field-flex {
    border-radius: 100px !important;
    border: 2px solid rgba(255, 255, 255, 0.2);
    padding: 0.5em 1.2em 0 1.2em;
  }

  &.mat-focused {
    .mat-form-field-flex {
      background-color: rgba(0, 0, 0, 0.05);
      border: 2px solid rgba(var(--iptv-brand-green-rgb), 1);
    }
  }

  &.mat-form-field-invalid {
    .mat-form-field-flex {
      background-color: rgba(0, 0, 0, 0.05);
      border: 2px solid rgba(var(--iptv-warn-rgb), 1);
    }
  }

  .mat-form-field-underline,
  .mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
    display: none;
  }

  // .mat-option {
  //   color: rgba(255, 255, 255, 0.8) !important;
  // }
  // .mat-option.mat-active {
  // color: red !important;
  // color: rgba(var(--iptv-brand-green-rgb),1) !important;
  // }
  // mat-select-panel
}

.mat-option {
  color: rgba(255, 255, 255, 0.8);
}

.mat-option.mat-active {
  color: rgba(var(--iptv-brand-green-rgb), 1);
}

.mat-form-field-appearance-fill.mat-form-field-disabled {
  .mat-form-field-label {
    color: white;
    opacity: 0.2;
  }

  .mat-form-field-flex {
    background-color: rgba(0, 0, 0, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.05);
  }
}

/** read only form fields **/

.iptv-field--read-only {
  .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label,
  .mat-form-field-appearance-fill.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: scale(1);
  }

  mat-label,
  .mat-form-field-label {
    font-size: 1.2rem;
    font-family: "Gilroy-Bold", Arial, Helvetica, sans-serif;
    color: #fff;
  }

  input {
    margin-top: 5px;
    font-size: 1rem;
  }

  // .mat-form-field-appearance-fill .mat-form-field-flex {
  // background-color: none !important;
  // }

  .mat-form-field-underline {
    display: none;
  }

  &.mat-form-field {
    min-width: 100px !important;
    pointer-events: none;
  }

  &.iptv-field--full-width {
    .mat-form-field-infix {
      width: 780px;
      // min-width: 100px;
    }
  }
}

/****/

/** mat-tree styles **/
.mat-tree {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  border: 2px solid #404040;
  overflow: hidden;
  overflow-y: scroll;

  .mat-checkbox-label {
    color: #fff;
  }

  .mat-checkbox-checked {
    // background-color: rgba(6, 194, 125,.3);
    .node-name {
      color: var(--iptv-brand-green);
    }

    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      z-index: -1;
      left: -10px;
      top: -5px;
      width: calc(100% + 25px);
      height: 35px;
      padding: 5px;
      border-radius: 100px;
      background-color: rgba(6, 194, 125, 0.1);
    }
  }
}

.display-num {
  color: var(--iptv-brand-green);
  font-family: "Gilroy-Bold", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  margin-left: 10px;
}

/*** paginator **/
.mat-paginator-container {
  justify-content: center !important;
  background-color: #191919;
  border-radius: 50px;
  width: inherit !important;
  margin: 0 auto;

  .mat-select {
    background: black;
    padding: 5px 10px;

    .mat-select-value {
      font-size: 1rem;
    }

    .mat-select-arrow {
      color: var(--iptv-brand-green);
    }
  }
}

.mat-option-text {
  font-size: 1rem;
}

.mat-paginator {
  background: none;
  color: #fff;
  font-size: 1rem;
  font-family: "Gilroy-Regular", Arial, Helvetica, sans-serif;
}

.mat-paginator-navigation-previous,
.mat-paginator-navigation-next {
  color: var(--iptv-brand-green);
  background: rgba(71, 72, 72, 0.25);
  // background: rgba(6, 194, 125,.1);
}

.mat-paginator-navigation-previous:not(.mat-button-disabled),
.mat-paginator-navigation-next:not(.mat-button-disabled) {
  background: rgba(6, 194, 125, 0.1);
}

.mat-paginator-navigation-previous {
  order: 1;
}

.mat-paginator-range-label {
  order: 2;
}

.mat-paginator-navigation-next {
  order: 3;
}

.mat-paginator {
  .mat-form-field {
    min-width: unset;
  }
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  background-color: #232323;
  color: white;
}

.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.7);
}

.iptv-displays-tree {
  .mat-icon {
    color: #fff;
  }

  mat-tree-node[aria-expanded="true"] {
    .mat-icon {
      color: var(--iptv-brand-green);
    }
  }

  .mat-checkbox-frame {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 4px;
  }

  .mat-checkbox-background {
    border-radius: 4px;
  }

  .mat-tree-node {
    min-height: 40px;
  }
}

.mat-expansion-panel-body {
  padding: 20px 24px 16px !important;
  background: #242424 !important;
}

.mat-expansion-indicator::after {
  color: var(--iptv-brand-green);
}

.mat-expanded {
  .mat-expansion-indicator::after {
    color: var(--iptv-brand-green);
  }
}

/*Table styles */

.mat-table {
  background: inherit;
}

.mat-header-cell {
  color: white;
}

.mat-cell {
  color: white;
}

td.mat-cell {
  border-bottom-color: rgba(250, 250, 250, 0.2);
  font-weight: 200;
}

th.mat-header-cell {
  font-size: 14px;
}

.navbar-nav {
  .mat-menu-panel {
    border-radius: 10px !important;
    max-width: 400px !important;
    font-family: "Gilroy-Regular", Arial, Helvetica, sans-serif;
    background-color: #232323;

    .mat-menu-item {
      font-family: "Gilroy-Regular", Arial, Helvetica, sans-serif;
      color: #fff;

      .mat-icon {
        margin-left: 0;
        font-size: unset;
        height: unset;
        width: unset;
        color: #fff;
      }
    }

    a:hover {
      text-decoration: none;
    }

    a.menu-selected {
      color: var(--iptv-brand-green);
      font-family: "Gilroy-Bold", Arial, Helvetica, sans-serif;
      // font-weight: bold;

      .mat-icon {
        color: var(--iptv-brand-green);
      }
    }

    .iptv-menu-warn {
      color: var(--iptv-warn);

      .mat-icon {
        color: var(--iptv-warn);
      }
    }
  }
}

.mat-progress-spinner {
  display: inline-flex !important;
  vertical-align: text-top;
}

.mat-tooltip {
  overflow: visible !important;
  max-width: 300px !important;
  background: rgba(72, 72, 72, 1) !important;
  padding: 20px !important;
  &.custom-tooltip {
    padding: 5px 10px !important;
    color: #fff !important;
    font-size: 0.9rem !important;
    background-color: rgb(22, 22, 22) !important;
    // border: 1px solid #fff !important;
    border-radius: 10px !important;
    margin: 5px auto !important;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.customMatTooltip::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent rgba(72, 72, 72, 1) transparent;
}

// ul.navbar-nav {
//   .mat-menu-panel .mat-menu-item .mat-icon {
//     margin-left: 0;
//   }
// }

.mat-button.mat-warn,
.mat-icon-button.mat-warn,
.mat-stroked-button.mat-warn {
  border: 1px solid var(--iptv-warn);
}

.mat-flat-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: #444;
}

.test-connnection-section {
  // flex-wrap: wrap;
  display: flex;
  flex-direction: row;
}

mat-grid-tile.test-connnection-section .mat-grid-tile-content {
  justify-content: flex-start;
  padding-left: 30px;
  flex-wrap: wrap !important;
}

.iptv-btn.test-connection {
  margin-bottom: 5px;
}

/**** form field loading indicator ****/

.connection-testing {
  // width: 200px;
  display: flex;
  margin: 0 15px;
}

.connection-testing div {
  width: 10px;
  height: 10px;
  background: var(--iptv-brand-green);
  border-radius: 50%;
  margin-left: 5px;
  margin-top: 10px;
  animation: updown 0.5s infinite alternate;
}

.connection-testing div:nth-child(2) {
  animation-delay: 0.2s;
}

.connection-testing div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes updown {
  to {
    opacity: 0.2;
    transform: translatey(-5px);
  }
}

.connection-message {
  width: 100%;
  margin: 5px 5px;
  text-align: center;
}

.connection-successful,
.connection-unsuccessful {
  width: 60%;
  border-radius: 5px;
  padding: 5px 5px;
  // min-width: 100px;
  display: block;
  // max-width: 220px;
  white-space: pre-wrap;
  margin: 5px 0;
  text-align: center;
  // white-space: nowrap;
}

.connection-successful {
  background-color: rgba(var(--iptv-brand-green-rgb), 0.1);
  color: var(--iptv-brand-green);
  text-transform: uppercase;
  font-size: 0.9rem;
  font-family: "Gilroy-Bold", Arial, Helvetica, sans-serif;

  &::after {
    // content: '';
    display: inline-block;
    transform: rotate(45deg);
    margin-left: 12px;
    height: 18px;
    width: 11px;
    border-bottom: 3px solid var(--iptv-brand-green);
    border-right: 3px solid var(--iptv-brand-green);
  }
}

.connection-unsuccessful {
  background-color: rgba(200, 0, 0, 0.1);
  color: var(--iptv-warn);
  text-transform: uppercase;
  font-size: 0.9rem;
  font-family: "Gilroy-Bold", Arial, Helvetica, sans-serif;

  &::after {
    // content: 'X';
    display: inline-block;
    // transform: rotate(45deg);
    margin-left: 12px;
    height: 18px;
    width: 18px;
    color: var(--iptv-warn);
    // border-bottom: 3px solid var(--iptv-warn);
    // border-right: 3px solid var(--iptv-warn);
  }
}

.mat-dialog-actions.mat-dialog-actions-align-end,
.mat-dialog-actions[align="end"] {
  justify-content: space-between !important;
}

.mat-snack-bar-container {
  border-radius: 15px !important;
  padding: 14px 30px !important;
  border: 1px solid rgba(6, 194, 125, 0.5);
  border-top: 15px solid rgba(6, 194, 125, 0.5);
  background-color: #202020;
  min-height: 130px !important;
  align-content: center;
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.mat-simple-snackbar {
}

.mat-simple-snack-bar-content {
  font-size: 1.1rem;
  font-family: "Gilroy-Regular", Arial, Helvetica, sans-serif;
}

.mat-simple-snackbar-action {
  margin-left: 30px !important;

  .mat-button {
    border: 1px solid #06c27d;
    color: #06c27d;
    text-transform: uppercase;
    min-width: 130px;

    .mat-button-wrapper {
    }
  }
}

.btn_highlight {
  border: 2px solid rgba(112, 112, 112, 0.4) !important;
  margin-right: 10px;
  color: #fff;

  img,
  mat-icon {
    opacity: 0.5;
  }

  &:hover {
    border: 2px solid var(--iptv-brand-green) !important;
    background-color: rgba(6, 194, 125, 0.1);

    img,
    mat-icon {
      opacity: 1;
    }
  }
}

.full-screen-dialog {
  display: flex !important;
  align-items: stretch !important;
  justify-content: stretch !important;
  width: 80vw !important;
  // height: 98vh !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;

  .mat-dialog-content {
    padding: 0;
    max-height: unset;
  }

  .mat-drawer-content {
    padding: 20px;
  }

  .mat-drawer {
    box-shadow: inset 0px 11px 15px -10px #000, inset 0px -11px 15px -10px #000;

    .mat-form-field {
      // min-width: 250px;
      // margin: 0 18px;
    }
  }

  mat-drawer {
    padding: 0 !important;
  }
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option.mat-option--extra-info {
  height: 5em;
}

.full-screen-dialog-preview {
  display: flex !important;
  align-items: stretch !important;
  justify-content: stretch !important;
  // width: 80vw !important;
  // height: 98vh !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;

  .mat-dialog-content {
    padding: 0;
    background-color: #000;
    max-height: unset;
  }

  .mat-dialog-container {
    background-color: #000;
  }

  mat-drawer {
    padding: 0 !important;
  }
}

.mat-menu-submenu-icon {
  color: var(--iptv-brand-green);
}

// .mat-menu-panel .mat-menu-item .mat-icon{
//   color: var(--iptv-brand-green);
// }

.mat-menu-content .mat-menu-item:hover {
  background-color: black;
  border-radius: unset !important;
  color: var(--iptv-brand-green);

  .mat-icon {
    color: var(--iptv-brand-green);
  }
}

.mat-menu-item[disabled]{
  background-color: transparent !important;
  border-radius: unset !important ;
  color: gray !important;
  .mat-icon {
    color: gray !important;
  }
  &:hover{
    background-color: none !important;
  }
}

// 232323

.selected-menu-item {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: unset !important;

  button.mat-menu-item {
    color: var(--iptv-brand-green);
  }

  .mat-icon {
    color: var(--iptv-brand-green);
  }
}

.mat-snack-bar-container {
  max-width: 100% !important;
}

.select-expansion-panel{
  background-color: transparent;
  box-shadow: none !important;
  border-radius: 0 !important;

  .mat-tooltip{
    padding: 5px !important;
    margin: 0px !important;
  }

  .mat-expansion-panel-header[aria-disabled=true] {
    color: rgba(255, 255, 255, 0.5) !important;

    .select-expansion-panel{
      &-header{
        &-title{
          &-label{
            color: rgba(255, 255, 255, 0.5) !important;
          }
          &-icon{
            color: rgba(255, 255, 255, 0.5) !important;
          }
          &-info-icon{
            color: rgba(255, 255, 255, 0.5) !important;
          }
        }
        &-arrow{
          &-icon{
            color: rgba(255, 255, 255, 0.5) !important;
          }
        }
      }
    }
  }

  .mat-expansion-panel-header-title{
    flex-grow: 2 !important;
  }
  .mat-expansion-panel-header-description{
    flex-grow: 1 !important;
  }

  .mat-expansion-panel-header {
    height: 40px !important;
    &.mat-expanded {
      height: 45px !important;
    }
  }

  .mat-expansion-panel-body{
    padding: 8px 0px 0px 0px !important;
  }

  &-header{
    padding: 0px !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.23);
    .mat-content{
      margin: 0px !important;
    }

    &-title{
      gap: 8px;
      margin: 0px !important;
      &-label{
        color: white;
        font-weight: bold;
        font-family: "Gilroy-Light", "Helvetica Neue", sans-serif;
        letter-spacing: 1px;
      }
      &-icon{
        width: 24px !important;
        height: 24px !important;
        font-size: 24px !important;
        color: white;
        &.img-icon{
          padding: 2px;
        }
      }
      &-info-icon{
        width: 16px !important;
        height: 16px !important;
        font-size: 16px !important;
        color: white;
      }
    }

    &-arrow{
      margin: 0px !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: flex-end !important;
      align-items: flex-end !important;
      &-icon{
        width: 24px !important;
        height: 24px !important;
        font-size: 24px !important;
        color: white;
      }
    }
  }

  &-selection-list{

    &-option{
      color: white;
      height: 48px;
      padding: 8px 16px 8px 16px;
      display: grid;
      align-items: center;
      border-radius: 8px;
      cursor: pointer;
      grid-auto-flow: column;
      grid-template-columns: 30px auto;
      &.select{
        grid-template-columns: auto 20px !important;
      }
      &-selector{
        .mat-checkbox-frame {
          border-color: rgba(255, 255, 255, 0.7) !important;
        }
        .mat-checkbox-checkmark-path {
          stroke: #474848  !important;
        }
        .mat-radio-button {
          margin-left: 0px !important;
        }
        .mat-radio-outer-circle {
          border-color: rgba(255, 255, 255, 0.7) !important;
        }
        .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
          border-color: #06c27d !important;
        }
      }
      &-text{
        font-family: "Gilroy-Light", "Helvetica Neue", sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .details{
          color:rgba(255, 255, 255, 0.5);
        }
      }
      &-selected{
        background-color: rgba(5, 174, 112, 0.16);
        -moz-transition: all .2s ease-in;
        -webkit-transition: all .2s ease-in;
        -o-transition: all .2s ease-in;
        transition: all .2s ease-in;
        &-icon{
          img {
            width: 20px !important;
            height: 20px !important;
            font-size: 20px !important;
          }

        }
      }
    }
  }
}

.custom-badge {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  line-height: 8px;
  right: 2px !important;
  top: 12px !important;
  position: absolute !important;
  font-family: "Gilroy-Light", "Helvetica Neue", sans-serif !important;
  .badge-icon{
    width: 8px;
    height: 8px;
    font-size: 8px;
    color:  var(--iptv-brand-orange) !important;
  }
}

.cdk-overlay-container:has(.custom-side-panel-dialog) {
  height: calc(100% - 64px);
  margin-top: 62px;
}
.custom-dialog-drawer-close{
  right: -409px !important;
}
.custom-side-panel-dialog {
  right: -409px;
  max-width: 409px !important;
  transition: right 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);

  position: absolute !important;
  // opacity: 1 !important;
  // visibility: visible !important;

  .mat-dialog-container {
    // background-color: #000;
    min-width: 409px;
    border-radius: 0 !important;
    border-left: 2px solid rgba(var(--iptv-brand-green-rgb), 0.2);

    .iptv-dialog__header {
      border-radius: 0;
      padding: 16px  24px 16px 24px;
      h1{
        font-size: 24px;
        padding-bottom: 8px;
      }
      > div {
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        button {
          background: transparent;
          color: #FFF;
          border: none;
        }
      }
    }

    .iptv-dialog__content{
      padding: 24px;
      .spacing{
        margin-top: 24px;
      }
    }


    .dialog-buttons {
      border-radius: 0;
      z-index: 999 !important;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      -webkit-box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.6);
      -moz-box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.6);
      box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.6);
    }
  }

  .mat-dialog-container h1,
  .mat-dialog-container .h1,
  .mat-dialog-title,
  p {
    margin-bottom: 0;
  }

  .mat-dialog-container h1,
  .mat-dialog-container .h1,
  .mat-dialog-title {
    padding: 0;
    font-size: 24px;
  }

  .mat-dialog-content {
    padding: 0;
    min-height: calc(100vh - 175px - 60px);
  }
  .action-buttons {
    text-transform: uppercase;
    padding-inline: 3em;
    font-weight: bold;
    min-width: 100px !important;
    color: white;
    &.apply{
      background-color: var(--iptv-brand-orange);
    }
  }
}

.content-library-dialog {
  right: -1133px;
  max-width: 1133px !important;
  transition: right 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);

  position: absolute !important;
  // opacity: 1 !important;
  // visibility: visible !important;

  .mat-dialog-container {
    // background-color: #000;
    border-radius: 0 !important;
    border-left: 2px solid rgba(var(--iptv-brand-green-rgb), 0.2);

    .iptv-dialog__header {
      border-radius: 0;
      padding-top: 10px;
    }

    .dialog-buttons {
      border-radius: 0;
      z-index: 999 !important;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      -webkit-box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.6);
      -moz-box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.6);
      box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.6);
    }
  }

  .mat-dialog-container h1,
  .mat-dialog-container .h1,
  .mat-dialog-title,
  p {
    margin-bottom: 0;
  }

  .mat-dialog-container h1,
  .mat-dialog-container .h1,
  .mat-dialog-title {
    font-size: 24px;
  }

  .mat-dialog-content {
    padding: 0;
    min-height: calc(100vh - 175px);
  }

  .content-library {
    .mat-checkbox-frame {
      background-color: #fff !important;
      border-radius: 3px;
      -webkit-box-shadow: inset 1.5px 1.5px 2px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: inset 1.5px 1.5px 2px 0px rgba(0, 0, 0, 0.3);
      box-shadow: inset 1.5px 1.5px 2px 0px rgba(0, 0, 0, 0.3);
    }
  }

  .library-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.8em;
    justify-content: flex-start;
    align-items: center;
    // flex-grow: 1;
    max-width: 100%;
    white-space: nowrap;

    img {
      width: 42px;
    }

    h1 {
      white-space: nowrap;
    }

    p {
      white-space: nowrap;
    }
  }
  .library-header-options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.8em;
    justify-content: flex-end;
    align-items: center;
    max-width: 100%;
    white-space: nowrap;

    img {
      width: 42px;
    }

    h1 {
      white-space: nowrap;
    }

    p {
      white-space: nowrap;
    }
  }
}

.dialog-drawer {
  // display: flex !important;
  // align-items: stretch !important;
  // justify-content: stretch !important;
  // width: 80vw !important;
  // height: 100vh !important;
  // margin: 0 !important;
  // padding: 0 !important;
  // overflow: hidden !important;
  // right: 0 !important;

  right: 0;
}

.dialog-drawer-close {
  right: -1133px;
}

.mat-chip.mat-standard-chip {
  background-color: rgba(var(--iptv-brand-green-rgb), 0.1);
  color: var(--iptv-brand-green);
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: #161616;
}

.mat-option.mat-option-disabled {
  // opacity: .3 !important;
  color: rgba(255, 255, 255, 0.1);
}

.iptv-select.mat-form-field-disabled {
  // border: 1px solid red;

  .mat-form-field-label {
    color: rgba(255, 255, 255, 0.7);
    opacity: 1;
  }

  .mat-form-field-flex {
    background: rgba(34, 34, 34, 0.5);
    border: 2px solid rgba(255, 255, 255, 0);
  }

  .mat-select-disabled,
  .mat-select-disabled .mat-select-value {
    // border: 1px solid red;
    color: var(--iptv-brand-green);
    // color: #fff;
  }

  .mat-select-disabled .mat-select-arrow {
    display: none;
  }
}

/* venue activation button style */
.mat-menu-content .mat-menu-item:hover {
  &.menu-activate-btn {
    border-radius: 40px !important;
    background: #006e45;
    color: #fff;
    // &.mat-button-disabled {
    //   pointer-events: none;
    // }
  }
}

.hr-line {
  border-top: 1px solid rgba(0, 0, 0, 0.75);
  display: block;
  width: 100%;
  height: 3px;
  margin: 5px auto;
  padding-top: 1px;

  &__inside {
    display: block;
    width: 100%;
    height: 1px;
    border-top: 1px solid rgba(112, 112, 112, 0.2);
  }
}

mat-sidenav.sidenav {
  .mat-drawer-inner-container {
    display: flex;
    -webkit-overflow-scrolling: touch;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

mat-expansion-panel.nav-module-expansion {
  background-color: #000;

  .mat-expansion-panel-content[style*="visibility: hidden"] * {
    display: none;
  }

  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    margin-right: 0;
  }

  .mat-expansion-panel-body {
    padding: 0 0 0 20px !important;
    background: none !important;
  }
}

.server-container .mat-content.mat-content-hide-toggle {
  margin-right: 0px;
}

.mat-autocomplete-panel {
  background-color: #232323;
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background-color: $black;
  color: $app-brand-color;
}

.mat-menu-panel .mat-menu-item .mat-icon {
  margin-left: 0;
}

.page-header__btn {
  text-transform: uppercase;
  min-width: 130px;
}

.readonly {
  &.mat-focused {
    .mat-form-field-label {
      color: #ffffffb3 !important;
    }
  }
  .mat-form-field-underline::before {
    color: #ffffffb3 !important;
  }
  .mat-form-field-ripple {
    display: none;
  }
}
.shared-config-dialog,
.device-edit-dialog {
  .sended-config-chip-list.mat-focused .mat-form-field-label {
    color: var(--iptv-brand-green) !important;
  }
  .send-config-chip-list.mat-focused .mat-form-field-label {
    color: var(--iptv-brand-green) !important;
  }
  .device-auth-chip-list.mat-focused .mat-form-field-label {
    color: var(--iptv-brand-green) !important;
  }
  .device-edit-chip-list.mat-focused .mat-form-field-label {
    color: var(--iptv-brand-green) !important;
  }

  .device-content-tags{
    .mat-chip.mat-standard-chip {
      color: rgba(255, 255, 255, 0.8) !important
    }
    .mat-chip-remove {
      color: #06c27d88;
      margin-left: 2px;
    }
  }


  .send-config-chip-list,
  .device-auth-chip-list,
  .device-edit-chip-list {
    .clip-text {
      margin-right: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    mat-chip-list mat-chip input {
      color: #ffffffb3 !important;
      background-color: transparent !important;
      border: 0px !important;
      outline: none !important;
    }

    .mat-form-field-label {
      color: #ffffffb3;
    }

    .mat-form-field-wrapper {
      background-color: #222222d1;
      border-radius: 10px 10px 0 0;
    }

    .mat-chip-list-wrapper .mat-standard-chip {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .mat-standard-chip .mat-chip-remove.mat-icon,
    .mat-standard-chip .mat-chip-remove .mat-icon {
      position: absolute;
      right: 0px;
      z-index: 10000;
    }

    .mat-chip-list-wrapper {
      margin: 0px 10px 0px 10px;
    }

    .mat-form-field-label-wrapper {
      margin: 5px 10px 0px 14px;
    }
  }

  .list-expanded {
    .mat-chip-list-wrapper {
      margin: 0px;
      flex-wrap: wrap !important;
      position: absolute;
    }
  }

  .sended-config-chip-list {
    .mat-form-field-label {
      color: #ffffffb3;
    }

    .mat-form-field-wrapper {
      background-color: #222222d1;
      border-radius: 10px 10px 0 0;
    }

    .mat-chip-list-wrapper .mat-standard-chip {
      max-width: 100%;
      overflow: visible;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
    }

    .mat-standard-chip .mat-chip-remove.mat-icon,
    .mat-standard-chip .mat-chip-remove .mat-icon {
      position: absolute;
      right: -6px;
      top: -6px;
      z-index: 10000;
      color: var(--iptv-brand-orange);
      opacity: 1 !important;
    }

    .mat-chip-list-wrapper {
      margin: 0px;
      flex-wrap: nowrap;
      position: relative;
    }

    .mat-form-field-label-wrapper {
      margin: 5px 10px 0px 14px;
    }

    .mat-standard-chip.mat-chip-with-trailing-icon {
      padding: 7px 8px;
    }
  }

  .mat-form-field-wrapper {
    padding: 0px !important;
  }

  .mat-form-field-underline {
    bottom: 0;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    bottom: 0;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #ffffff63 !important;
  }
}

.web-player-component {
  .web-player-config-select {
    .iptv-select {
      width: 100% !important;

      .mat-select-arrow {
        opacity: 0;
      }

      .mat-icon {
        color: #06c27d;
      }

      .mat-form-field-infix {
        padding: 0 0 0.25em 0 !important;
      }

      .mat-form-field-flex {
        padding: 0.25em 0.5em 0 1em !important;
      }
    }
  }

  .mat-select-panel {
    background: #232323;
    border-radius: 10px;
  }

  .mat-form-field-wrapper {
    padding: 0px !important;
  }
}

.send-config-max-devices.mat-form-field {
  min-width: 0px !important;
}

.vl {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  height: 35px;
}

.deleteDialogPanel{
  .mat-dialog-container {
    min-width: 500px !important;
    max-height: 250px !important;
    height: 250px !important;
    width: 500px !important;
    border-radius: 8px !important;
  }
  .mat-dialog-container .mat-dialog-content {
    min-height: 120px !important;
    padding: 0 30px !important;
  }
}

.mi-form-container {
  .mat-form-field-appearance-fill .mat-form-field-underline::before {
    background-color: var(--iptv-brand-green) !important;
  }
  .mat-form-field-ripple {
    background-color: var(--iptv-brand-green) !important;
  }
}

.video-player-img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 12px;
  opacity: 1;
  transition: opacity .2s ease-in-out;
}

.video-player-img.fade {
  opacity: 0;
}


.custom-snackbar {
  transition: opacity 1s ease-in-out; // Add transition property
}


.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration:.2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fade-out {
	opacity: 0;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
	animation-duration:.2s;
}

@keyframes fadeOutOpacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}


.live-viewing-add-dialog{
  min-width: 1100px;
  width: 1100px;
  max-width: 100%;
}


.live-viewing-pause-dialog, .live-voewing-booting-up-dialog{
  min-width: 500px;
  width: 500px;
  max-width: 100%;
}
